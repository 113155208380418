<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="责任人">
          <el-input v-model="head" placeholder="请输入责任人" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable" icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus" v-if="isAdmin">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="keypartsList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column prop="head" label="责任人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="buildingName" label="建筑名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="fireSafeImportanceName" label="重点部位名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="location" label="所在位置" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="height" label="所在高度" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="fireFacility" label="消防设施" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="fireSafeManageFacility" label="消防安全管理措施" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top" v-if="isAdmin">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="isAdmin">
              <el-button type="danger" @click="delKeyParts(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="详情" placement="top">
              <el-button type="primary" @click="open('check',scope.row)" size="mini" icon="el-icon-view" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" keyparts="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 表单 -->
    <el-dialog :title="optFlag?'添加重点部位':'修改重点部位'" :visible.sync="keypartsShow" width="40%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="keyparts" ref="keypartsForm" :rules="rules" label-width="180px">
        <el-form-item label="建筑面积" prop="area">
          <el-input-number v-model="keyparts.area" size="small" :min="0" :controls="true">
          </el-input-number>
        </el-form-item>
        <el-form-item label="是否设置禁止类消防标识" prop="isForbid">
          <el-radio-group v-model="keyparts.isForbid">
            <el-radio v-for="(item,i) in [{id:0,text:'是'},{id:1,text:'否'}]" :key="i" :label="item.id">
              {{item.text}}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="安全出口数" prop="exitNum">
          <el-input-number v-model="keyparts.exitNum" size="small" :min="0" :controls="true">
          </el-input-number>
        </el-form-item>
        <el-form-item label="所在位置" prop="location">
          <el-input v-model="keyparts.location" placeholder="请输入所在位置" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="火种情况" prop="fireCondition">
          <el-radio-group v-model="keyparts.fireCondition">
            <el-radio v-for="(item,i) in [{id:0,text:'有火种'},{id:1,text:'无火种'}]" :key="i" :label="item.id">
              {{item.text}}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="火灾危险性" prop="fireDanger">
          <el-select v-model="keyparts.fireDanger" placeholder="请选择火灾危险性" clearable size="small" style="float:left">
            <el-option v-for="(item,i) in dangerList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属建筑" prop="buildingId">
          <el-select v-model="keyparts.buildingId" placeholder="请选择所属建筑" clearable size="small" style="float:left">
            <el-option v-for="(item,i) in buildingList" :key="i" :label="item.buildingName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消防安全重点部位名称" prop="fireSafeImportanceName">
          <el-input v-model="keyparts.fireSafeImportanceName" placeholder="请输入消防安全重点部位名称" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="建筑结构" prop="structureId">
          <el-select v-model="keyparts.structureId" placeholder="请选择建筑结构">
            <el-option v-for="(item,i) in structureList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用功能" prop="useFunction">
          <el-input v-model="keyparts.useFunction" placeholder="请输入使用功能" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="消防电梯数" prop="elevatorNum">
          <el-input-number v-model="keyparts.elevatorNum" size="small" :min="0" :controls="true">
          </el-input-number>
        </el-form-item>
        <el-form-item label="所在高度" prop="height">
          <el-input-number v-model="keyparts.height" size="small" :min="0" :controls="true">
          </el-input-number>
        </el-form-item>
        <el-form-item label="耐火等级" prop="fireResistLevel">
          <el-select v-model="keyparts.fireResistLevel" placeholder="请选择耐火等级" clearable size="small" style="float:left">
            <el-option v-for="(item,i) in levelList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="确立原因" prop="reason">
          <el-select v-model="keyparts.reason" placeholder="请选择确立原因" clearable size="small" style="float:left">
            <el-option v-for="(item,i) in reasonList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="防火标志的设立情况" prop="fireFlagCondition">
          <el-select v-model="keyparts.fireFlagCondition" placeholder="请选择防火标志的设立情况" clearable size="small" style="float:left">
            <el-option v-for="(item,i) in flagConditionList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="危险源情况" prop="dangerCondition">
          <el-select v-model="keyparts.dangerCondition" placeholder="请选择危险源情况" clearable size="small" style="float:left">
            <el-option v-for="(item,i) in hazardList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="责任人" prop="head">
          <el-input v-model="keyparts.head" placeholder="请输入责任人" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="消防安全管理措施" prop="fireSafeManageFacility">
          <el-input v-model="keyparts.fireSafeManageFacility" placeholder="请输入消防安全管理措施" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="消防设施" prop="fireFacility">
          <el-input v-model="keyparts.fireFacility" placeholder="请输入消防设施" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="是否设置提示类消防标识" prop="isFireFlag">
          <el-radio-group v-model="keyparts.isFireFlag">
            <el-radio v-for="(item,i) in [{id:0,text:'是'},{id:1,text:'否'}]" :key="i" :label="item.id">
              {{item.text}}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="keypartsShow = false">取 消</el-button>
        <el-button type="primary" @click="addKeyParts" v-show="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateKeyParts" v-show="!optFlag">修 改</el-button>
      </span>
    </el-dialog>
    <el-drawer :title="keyparts.buildingName" :visible.sync="infoShow" direction="rtl" :before-close="handleClose">
      <el-form label-position="left" inline class="drawer-form" label-width="180px">
        <el-form-item label="建筑面积">
          <span>{{keyparts.area}}</span>
        </el-form-item>
        <el-form-item label="是否设置禁止类消防标识">
          <el-tag type="success" size="size" v-show="keyparts.isForbid===0">是</el-tag>
          <el-tag type="danger" size="size" v-show="keyparts.isForbid===1">否</el-tag>
        </el-form-item>
        <el-form-item label="安全出口数">
          <span>{{keyparts.exitNum}}</span>
        </el-form-item>
        <el-form-item label="所在位置">
          <span>{{keyparts.location}}</span>
        </el-form-item>
        <el-form-item label="火种情况">
          <span>{{keyparts.fireCondition===0?'有火种':'无火种'}}</span>
        </el-form-item>
        <el-form-item label="火灾危险性">
          <span>{{keyparts.fireDangerName}}</span>
        </el-form-item>
        <el-form-item label="所属建筑">
          <span>{{keyparts.buildingName}}</span>
        </el-form-item>
        <el-form-item label="消防安全重点部位名称">
          <span>{{keyparts.fireSafeImportanceName}}</span>
        </el-form-item>
        <el-form-item label="建筑结构">
          <span>{{keyparts.structureName}}</span>
        </el-form-item>
        <el-form-item label="使用功能">
          <span>{{keyparts.useFunction}}</span>
        </el-form-item>
        <el-form-item label="消防电梯数">
          <span>{{keyparts.elevatorNum}}</span>
        </el-form-item>
        <el-form-item label="所在高度">
          <span>{{keyparts.height}}</span>
        </el-form-item>
        <el-form-item label="耐火等级">
          <span>{{keyparts.fireResistLevelName}}</span>
        </el-form-item>
        <el-form-item label="确立原因">
          <span>{{keyparts.reason}}</span>
        </el-form-item>
        <el-form-item label="防火标志的设立情况">
          <span>{{keyparts.fireFlagConditionName}}</span>
        </el-form-item>
        <el-form-item label="危险源情况">
          <span>{{keyparts.dangerConditionName}}</span>
        </el-form-item>
        <el-form-item label="责任人">
          <span>{{keyparts.head}}</span>
        </el-form-item>
        <el-form-item label="消防安全管理措施">
          <span>{{keyparts.fireSafeManageFacility}}</span>
        </el-form-item>
        <el-form-item label="消防设施">
          <span>{{keyparts.fireFacility}}</span>
        </el-form-item>
        <el-form-item label="是否设置提示类消防标识">
          <el-tag type="success" size="size" v-show="keyparts.isFireFlag===0">是</el-tag>
          <el-tag type="danger" size="size" v-show="keyparts.isFireFlag===1">否</el-tag>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentPage: 1,
      total: 0,
      head: '',
      keypartsList: [],
      dangerList: [],
      buildingList: [],
      structureList: [],
      levelList: [],
      reasonList: [],
      flagConditionList: [],
      hazardList: [],
      keypartsId: '',
      keyparts: {
        area: 0,
        buildingId: '',
        dangerCondition: '',
        elevatorNum: 0,
        exitNum: 0,
        fireCondition: '',
        fireDanger: '',
        fireFacility: '',
        fireFlagCondition: '',
        fireResistLevel: '',
        fireSafeImportanceName: '',
        fireSafeManageFacility: '',
        head: '',
        height: 0,
        isFireFlag: '',
        isForbid: '',
        location: '',
        reason: '',
        structureId: '',
        useFunction: ''
      },
      keypartsCopy: {},
      keypartsShow: false,
      infoShow: false,
      optFlag: true,
      loading: false,
      rules: {
        area: [
          { required: true, message: '请输入建筑面积', trigger: 'blur' }
        ],
        isForbid: [
          { required: true, message: '请选择是否设置禁止类消防标识', trigger: 'change' }
        ],
        exitNum: [
          { required: true, message: '请输入安全出口数', trigger: 'blur' }
        ],
        location: [
          { required: true, message: '请输入所在位置', trigger: 'blur' }
        ],
        fireCondition: [
          { required: true, message: '请选择火种情况', trigger: 'change' }
        ],
        fireDanger: [
          { required: true, message: '请选择火灾危险性', trigger: 'change' }
        ],
        buildingId: [
          { required: true, message: '请选择所属建筑', trigger: 'change' }
        ],
        fireSafeImportanceName: [
          { required: true, message: '请输入消防安全重点部位名称', trigger: 'blur' }
        ],
        structureId: [
          { required: true, message: '请选择建筑结构', trigger: 'change' }
        ],
        useFunction: [
          { required: true, message: '请输入使用功能', trigger: 'blur' }
        ],
        elevatorNum: [
          { required: true, message: '请输入消防电梯数', trigger: 'blur' }
        ],
        height: [
          { required: true, message: '请输入所在高度', trigger: 'blur' }
        ],
        fireResistLevel: [
          { required: true, message: '请选择耐火等级', trigger: 'change' }
        ],
        reason: [
          { required: true, message: '请选择确立原因', trigger: 'change' }
        ],
        fireFlagCondition: [
          { required: true, message: '请选择防火标志的设立情况', trigger: 'change' }
        ],
        dangerCondition: [
          { required: true, message: '请选择危险源情况', trigger: 'change' }
        ],
        head: [
          { required: true, message: '请输入责任人', trigger: 'blur' }
        ],
        fireSafeManageFacility: [
          { required: true, message: '请输入消防安全管理措施', trigger: 'blur' }
        ],
        fireFacility: [
          { required: true, message: '请输入消防设施', trigger: 'blur' }
        ],
        isFireFlag: [
          { required: true, message: '请选择是否设置提示类消防标识', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.initTable()
    this.getDangerList()
    this.getBuilding()
    this.getStructure()
    this.getLevel()
    this.getReason()
    this.getFlagCondition()
    this.getHazard()
    this.keypartsCopy = JSON.stringify(this.keyparts)
  },
  methods: {
    handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async initTable () {
      const data = {}
      if (this.head) {
        data.head = this.head
      }
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading = true
      const { data: result } = await this.$axios.get('/fireImportanceCondition/list', { params: data })
      if (result.code === 200) {
        this.keypartsList = result.data.result
        this.total = result.data.total
        this.loading = false
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    async addKeyParts () {
      this.$refs.keypartsForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/fireImportanceCondition/add', this.keyparts)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.keypartsShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async updateKeyParts () {
      this.$refs.keypartsForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/fireImportanceCondition/edit/${this.keypartsId}`, this.keyparts)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.keypartsShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除某条数据
    async delKeyParts (id) {
      const confirm = await this.$confirm('此操作将永久删除该重点部位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/fireImportanceCondition/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.keypartsShow = true
          this.$nextTick(() => {
            this.$refs.keypartsForm.clearValidate()
          })
          break
        case 'edit':
          this.optFlag = false
          this.keyparts = JSON.parse(JSON.stringify(row))
          this.keypartsShow = true
          this.keypartsId = row.id
          this.$nextTick(() => {
            this.$refs.keypartsForm.clearValidate()
          })
          break
        case 'check':
          this.keyparts = row
          this.infoShow = true
      }
    },
    async getBuilding () {
      const { data: result } = await this.$axios.get('/building/listAllBuilding')
      if (result.code === 200) {
        this.buildingList = result.data
      }
    },
    async getDangerList () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'fireDanger' } })
      if (result.code === 200) {
        this.dangerList = result.data
      }
    },
    // 查找建筑结构
    async getStructure () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'buildingStructure' } })
      if (result.code === 200) {
        this.structureList = result.data
      }
    },
    // 查找耐火等级
    async getLevel () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'fireResistLevel' } })
      if (result.code === 200) {
        this.levelList = result.data
      }
    },
    // 查找确立原因
    async getReason () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'sureReason' } })
      if (result.code === 200) {
        this.reasonList = result.data
      }
    },
    // 查找防火标志设立情况
    async getFlagCondition () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'fireFlagCondition' } })
      if (result.code === 200) {
        this.flagConditionList = result.data
      }
    },
    // 查找危险源情况
    async getHazard () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'dangerThingCondition' } })
      if (result.code === 200) {
        this.hazardList = result.data
      }
    },
    clear () {
      this.keyparts = JSON.parse(this.keypartsCopy)
      this.$refs.keypartsForm.clearValidate()
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  box-shadow: none !important;
}
.container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border: none !important;
}
::v-deep  :focus {
  outline: 0;
}
::v-deep .el-drawer__body {
  overflow-y: scroll;
  .drawer-form {
    font-size: 0;
    padding: 10px 40px;
    .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
      label {
        color: #99a9bf !important;
        float: left;
      }
      .el-form-item__content {
        float: left;
      }
    }
  }
}
</style>
